export const EXLY_COMMUNITY_FEED_TYPES = {
  COMMUNITY_CHANNEL: 1,
  COMMUNITY: 2,
  // BLOG: 3, // not used
};

export const EXLY_COMMUNITY_CHANNEL_TYPES = {
  GROUP_CHAT: 1,
  POST: 2,
  DIRECT_CHAT: 3,
};

export type IBrandedCommunityTheme = {
  colors: {
    color1: string;
    color2: string;
    color3: string;
    color4: string;
    color5?: string;
  };
  font: {
    slug: string;
    title: string;
    url: string;
  };
};

export const DEFAULT_BRANDED_COMMUNITY_THEME: IBrandedCommunityTheme = {
  colors: {
    color1: "",
    color2: "",
    color3: "",
    color4: "",
    color5: "",
  },
  font: {
    slug: "",
    title: "",
    url: "",
  },
};
