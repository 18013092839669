import { createContext, useContext } from "react";
import {
  DEFAULT_BRANDED_COMMUNITY_THEME,
  IBrandedCommunityTheme,
} from "../../constants/BrandedCommunity/Miscellaneous";

export const BrandedCommunityThemeContext =
  createContext<IBrandedCommunityTheme>(DEFAULT_BRANDED_COMMUNITY_THEME);

export const useBrandedCommunityThemeContext = () =>
  useContext(BrandedCommunityThemeContext);
