import { IBrandedCommunityTheme } from "../../../../../constants/BrandedCommunity/Miscellaneous";
import { getColorsFromBrandedCommunityTheme } from "../../../../../utils/BrandedCommunity/BrandedCommunityThemeContext";
import { getStylesObjFromCss } from "../../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "20px 24px",
      borderBottom: "1px solid #e7e6e4",
      gap: "8px",
    },
    title: {
      color: "rgba(0, 0, 0, 0.85)",
      fontSize: "18px",
      fontWeight: 700,
    },
    cta: {
      fontSize: "14px !important",
      fontWeight: "500 !important",
      textTransform: "none !important" as any,
      padding: "0px !important",
    },
  },
  componentName: "MembersPanelHeader",
});

export const getThemedStyles = ({
  theme,
}: {
  theme: IBrandedCommunityTheme;
}) => {
  const colors = getColorsFromBrandedCommunityTheme(theme);
  return getStylesObjFromCss({
    styles: {
      cta: {
        color: (colors.color1 || "#493AB1") + " !important",
      },
    },
    componentName: "MembersPanelHeader",
  });
};
