import React, { FC } from "react";

import MembersPanelHeader from "./components/Header/MembersPanelHeader";
import MembersList from "./components/MembersList/MembersList";
import Status from "./components/Status/Status";

import { classNames } from "../../../utils/common/styling";

import { IMembersPanel } from "./IMembersPanel";
import { styles } from "./MembersPanel.styles";
import { BrandedCommunityThemeContext } from "../BrandedCommunityThemeContext";
import { DEFAULT_BRANDED_COMMUNITY_THEME } from "../../../constants/BrandedCommunity/Miscellaneous";

const MembersPanel: FC<IMembersPanel.IProps> = ({
  theme = DEFAULT_BRANDED_COMMUNITY_THEME,
  className,
  headerProps,
  statusProps,
  memberListProps,
}) => {
  return (
    <BrandedCommunityThemeContext.Provider value={theme}>
      <div className={classNames(styles.root, className)}>
        <MembersPanelHeader {...headerProps} />

        <Status {...statusProps} />

        <MembersList {...memberListProps} />
      </div>
    </BrandedCommunityThemeContext.Provider>
  );
};

export default MembersPanel;
