import { getStylesObjFromCss } from "../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {},
    textArea: {
      resize: "none",
      border: "0",
      outline: "0",
      padding: "0",
      fontFamily: "inherit",
      width: "100%",
      height: "100%",
      display: "block",
    },
  },
  componentName: "TextAreaWithAutoHeight",
});
