import { images_format_keys } from "../../File/constants/File.constants";

export const IMAGE_CDN_FORMATS = {
  AUTO: "auto",
  AVIF: images_format_keys.avif,
  JPEG: images_format_keys.jpeg,
  JPG: images_format_keys.jpg,
  PNG: images_format_keys.png,
  WEBP: images_format_keys.webp,
  SVG: images_format_keys.svg,
  TIFF: images_format_keys.tiff,
} as const;
