import { IBrandedCommunityTheme } from "../../../constants/BrandedCommunity/Miscellaneous";
import { getColorsFromBrandedCommunityTheme } from "../../../utils/BrandedCommunity/BrandedCommunityThemeContext";
import { getStylesObjFromCss } from "../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    youAreUpToDateContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    youAreUpToDateText: {
      marginRight: "5px",
    },
    monthSeparatorText: {
      color: "rgba(0, 0, 0, 0.4)",
      fontWeight: "500",
      fontSize: "14px",
      textTransform: "uppercase",
    },
  },
  componentName: "PostsList",
});

export const getThemedStyles = ({
  theme,
}: {
  theme: IBrandedCommunityTheme;
}) => {
  const colors = getColorsFromBrandedCommunityTheme(theme);
  return getStylesObjFromCss({
    styles: {
      youAreUpToDateText: colors.color1
        ? { color: colors.color1 }
        : {
            background: "linear-gradient(to right, #493ab1 0%, #166cd1 100%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          },
    },
    componentName: "PostsList",
  });
};
