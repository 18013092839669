import { appendURLSearchParams } from "../../../../../utils/common/url";
import { IMAGE_CDN_FORMATS } from "../constants/ExlyImage.constants";
import { isImageFromOptimizingCdn } from "./isImageFromOptimizingCdn";

/**
 * get the optimsed image url by appending search params format as "auto"
 * where auto formats fetches the best case format as per transformations and
 * quality as default.
 */
export const getOptimisedImageUrl = ({
  imageUrl,
  fetchWidth,
  cdnBaseUrl,
  format = IMAGE_CDN_FORMATS.AUTO,
  quality,
}: {
  imageUrl: string;
  fetchWidth: number;
  cdnBaseUrl: string;
  format?: string;
  quality?: number;
}) =>
  isImageFromOptimizingCdn(imageUrl, cdnBaseUrl)
    ? appendURLSearchParams(imageUrl, {
        format,
        width: fetchWidth,
        quality,
      })
    : imageUrl;
