import React, { FC } from "react";

import NextIcon from "@mui/icons-material/NavigateNextRounded";

import { Button } from "@mui/material";

import { classNames } from "../../../../../utils/common/styling";

import { IMembersPanelHeader } from "./IMembersPanelHeader";
import { getThemedStyles, styles } from "./MembersPanelHeader.styles";
import { useBrandedCommunityThemeContext } from "../../../BrandedCommunityThemeContext";

const MembersPanelHeader: FC<IMembersPanelHeader.IProps> = ({
  title = "Members",
  ctaText = "See all",
  showCta,
  onClick,
  hide,
  classes = {},
}) => {
  const theme = useBrandedCommunityThemeContext();
  if (hide) return null;

  const themedStyles = getThemedStyles({ theme });

  return (
    <div className={classNames(styles.root, classes.root)}>
      <div className={classNames(styles.title, classes.title)} title={title}>
        {title}
      </div>
      {showCta && (
        <Button
          className={classNames(themedStyles.cta, styles.cta)}
          endIcon={<NextIcon />}
          onClick={onClick}
        >
          {ctaText}
        </Button>
      )}
    </div>
  );
};

export default MembersPanelHeader;
