export const images_format_keys = {
  avif: "avif",
  heic: "heic",
  heif: "heif",
  jpeg: "jpeg",
  jpg: "jpg",
  png: "png",
  webp: "webp",
  gif: "gif",
  svg: "svg",
  tiff: "tiff",
} as const;
