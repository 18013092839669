import React, { FC } from "react";
import { classNames } from "../../../../utils/common/styling";
import { ISectionCTA } from "./ISectionCTA";
import { getThemedStyles, styles } from "./SectionCTA.styles";
import { useBrandedCommunityThemeContext } from "../../BrandedCommunityThemeContext";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/CreateOutlined";

const SectionCTA: FC<ISectionCTA.IProps> = ({
  label,
  active,
  icon,
  onClick,

  className,
  labelClassName,
  labelStyle,

  showActions,
  onEdit,
}) => {
  const theme = useBrandedCommunityThemeContext();
  const themedStyles = getThemedStyles({ theme });

  return (
    <div
      className={classNames(
        styles.root,
        active && styles.active,
        active && themedStyles.active,
        className,
      )}
      onClick={onClick}
    >
      <div className={styles.labelContainer}>
        {icon}
        <span
          className={classNames(
            styles.labelText,
            active ? themedStyles.activeText : styles.notActiveText,
            labelClassName,
          )}
          style={labelStyle}
        >
          {label}
        </span>
      </div>
      {showActions && (
        <div className={styles.actions}>
          <IconButton
            onClick={(e) => {
              // to not trigger `onClick` of cta itself on click of edit button
              e.stopPropagation();

              onEdit?.(e);
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default SectionCTA;
